import React from "react";

class EmailVerified extends React.Component {
  render() {
    return (
      <div>
        <h1>Your email has been Verified</h1>
        <p>Now you have all access to this site</p>
      </div>
    );
  }
}

export default EmailVerified;
