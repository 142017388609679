import React from "react";

const NotAuthorized = () => (
  <div>
    <h1>Not Authorized</h1>
    <p>you don't have permission to access</p>
  </div>
);

export default NotAuthorized;
